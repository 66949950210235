<template>
  <company-form />
</template>

<script>
import CompanyForm from '@/views/client/user/components/CompanyForm.vue'

export default {
  components: {
    CompanyForm
  }
}
</script>

  <style lang="scss">
@import "@core/scss/base/pages/app-file-manager";
</style>